
import { Component, Mixins, Prop } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import FormCard from "@/components/FormCard.vue";
import RequestDetailForm from "@/components/forms/RequestDetailForm.vue";
import LogoSingle from "@/components/global/LogoSingle.vue";

@Component({
  components: {
    FormCard,
    RequestDetailForm,
    LogoSingle
  }
})
export default class RequestDetails extends Mixins(FormValidator) {
  @Prop(Number) readonly id_ticket: number | undefined;
  @Prop({ default: false }) readonly quick_search!: boolean;
  private ticket = {
    number: "",
    message: "",
    current_status: "",
    date: "",
    service: "",
    files: [],
    history: []
  };
  private user = {
    name: "",
    father_last_name: "",
    mother_last_name: "",
    rut: "",
    email: "",
    document_type: ""
  };
  private ticket_found = false;

  protected downloading_file = false;
  protected download_progress = 0;

  protected updateProgressFromChild(value: number) {
    this.download_progress = value;
  }

  protected get progressLabel() {
    return Math.round(this.download_progress * 100).toString() + "%";
  }

  private setFormData(data: any) {
    this.setTicketData(data.ticket);
    this.setUserData(data.persona);
    this.ticket_found = true;
  }

  private setUserData(user: any) {
    this.user.name = user.nombre;
    this.user.father_last_name = user.apellido_paterno;
    this.user.mother_last_name = user.apellido_materno;
    this.user.rut = user.rut;
    this.user.email = user.email;
    this.user.document_type = user.tipo_documento.toString();
  }

  private resetTicketData() {
    this.ticket.number = "";
    this.ticket.message = "";
    this.ticket.current_status = "";
    this.ticket.date = "";
    this.ticket.service = "";
    this.ticket.files = [];
    this.ticket.history = [];
  }

  private resetUserData() {
    this.user.name = "";
    this.user.father_last_name = "";
    this.user.mother_last_name = "";
    this.user.rut = "";
    this.user.email = "";
  }

  private resetData() {
    this.ticket_found = false;
    this.resetTicketData();
    this.resetUserData();
  }

  private setTicketData(ticket: any) {
    this.ticket.number = ticket.numero;
    this.ticket.message = ticket.mensaje;
    this.ticket.current_status = ticket.estado_ticket;
    this.ticket.date = ticket.fecha;
    this.ticket.service = ticket.servicio.nombre;
    for (let i = 0; i < ticket.ticket_adjunto.length; i++) {
      let file = {
        id: ticket.ticket_adjunto[i].id,
        original: ticket.ticket_adjunto[i].original
      };
      (this.ticket.files as any).push(file);
    }
    for (let i = 0; i < ticket.ticket_historial.length; i++) {
      let history = {
        status: ticket.ticket_historial[i].estado_ticket,
        message: ticket.ticket_historial[i].mensaje,
        date: ticket.ticket_historial[i].fecha,
        attachment: ticket.ticket_historial[i].adjunto
      };
      (this.ticket.history as any).push(history);
    }
  }

  private download(id: number, nombre: string) {
    if (this.downloading_file) {
      this.$q.notify({
        message: "Solo puede descargar un archivo a la vez",
        color: "accent",
        position: "top",
        timeout: 5000
      });
      return 0;
    }
    this.downloading_file = true;
    this.$q.notify({
      message: this.$t("notification.download_start") as string,
      color: "accent",
      position: "top",
      timeout: 5000
    });
    this.$axios
      .post(
        "tickets/adjunto",
        {
          tipo_documento: Number(this.user.document_type),
          rut: this.user.rut,
          id: id
        },
        {
          responseType: "blob",
          onDownloadProgress: progressEvent => {
            this.download_progress =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) /
              100;
          }
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nombre); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloading_file = false;
        this.download_progress = 0;
      })
      .catch(error => {
        this.$q.notify({
          message: this.$t("notification.download_error") as string,
          color: "accent",
          position: "top",
          timeout: 5000
        });
        this.downloading_file = false;
        this.download_progress = 0;
      });
  }

  private downloadHistory(id: number, nombre: string) {
    if (this.downloading_file) {
      this.$q.notify({
        message: "Solo puede descargar un archivo a la vez",
        color: "accent",
        position: "top",
        timeout: 5000
      });
      return 0;
    }
    this.downloading_file = true;
    this.$q.notify({
      message: this.$t("notification.download_start") as string,
      color: "accent",
      position: "top",
      timeout: 5000
    });
    this.$axios
      .post(
        "tickets/front/historial/adjunto",
        {
          tipo_documento: Number(this.user.document_type),
          rut: this.user.rut,
          id: id
        },
        {
          responseType: "blob",
          onDownloadProgress: progressEvent => {
            this.download_progress =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) /
              100;
          }
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nombre); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloading_file = false;
        this.download_progress = 0;
      })
      .catch(error => {
        this.$q.notify({
          message: this.$t("notification.download_error") as string,
          color: "accent",
          position: "top",
          timeout: 5000
        });
        this.downloading_file = false;
        this.download_progress = 0;
      });
  }

  private goBack() {
    if (this.$store.state.logged) {
      this.$router.push({ name: "myrequests" });
    } else {
      this.resetData();
    }
  }

  private getStatus(status: number) {
    let status_name = "Desconocido";
    switch (status) {
      case 1:
        status_name = "Gestión";
        break;
      case 2:
        status_name = "Revisión";
        break;
      case 3:
        status_name = "Derivado";
        break;
      case 4:
        status_name = "VB Jefe";
        break;
      case 5:
        status_name = "VB Director";
        break;
      case 6:
        status_name = "Cerrado";
        break;
      default:
        status_name = "Desconocido";
        break;
    }
    return status_name;
  }

  private getTextColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = "text-yellow";
        break;
      case 2:
        color = "text-orange";
        break;
      case 3:
        color = "text-purple";
        break;
      case 4:
        color = "text-cyan";
        break;
      case 5:
        color = "text-blue";
        break;
      case 6:
        color = "text-green";
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private getBtnColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = "yellow";
        break;
      case 2:
        color = "orange";
        break;
      case 3:
        color = "purple";
        break;
      case 4:
        color = "cyan";
        break;
      case 5:
        color = "blue";
        break;
      case 6:
        color = "green";
        break;
      default:
        color = "";
        break;
    }
    return color;
  }
  private humanDate(date: any) {
    let date_object = new Date(date);
    let date_time = date_object.toLocaleString("es-CL", { timeZone: "UTC" });
    return date_time.split(" ")[0];
  }

  private humanTime(date: any) {
    let date_object = new Date(date);
    let date_time = date_object.toLocaleString("es-CL", { timeZone: "UTC" });
    return date_time.split(" ")[1];
  }

  private humanDateTime(date: any) {
    let date_object = new Date(date);
    return date_object.toLocaleString("es-CL", { timeZone: "UTC" });
  }

  private formatRutShow(rut: any) {
    return rut
      .replace("-", "")
      .replace(/[.-]/g, "")
      .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
  }
}
