
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
interface requestData {
  document_type: string;
  document_id: string;
  ticket_number: string;
}

@Component
export default class RequestDetailForm extends Mixins(FormValidator) {
  @Prop(Number) readonly id_ticket: number | undefined;
  @Prop({ default: false }) readonly quick_search!: boolean;
  options: object[] = [
    { label: "DNI", value: "0" },
    { label: "RUT", value: "1" },
    { label: "Pasaporte", value: "2" }
  ];
  protected formData: requestData = {
    document_type: "1",
    document_id: "",
    ticket_number: ""
  };

  private ticket_found = true;

  private mounted() {
    if (this.quick_search) {
      this.formData.ticket_number = this.id_ticket!.toString();
      this.formData.document_id = this.$store.state.user.rut;
      this.formData.document_type = this.$store.state.user.document_type;
      this.getTicket();
    }
  }

  private getTicket(): void {
    this.$axios
      .post("tickets/detalle", {
        tipo_documento: Number(this.formData.document_type),
        rut: this.formData.document_id,
        id_ticket: Number(this.formData.ticket_number)
      })
      .then(response => {
        this.setTicketData(response.data.response);
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 403) {
          this.setTicketNotFound();
        }
      });
  }

  private setTicketData(data: any) {
    this.$emit("setTicketData", data);
  }

  private setTicketNotFound() {
    this.ticket_found = false;
    (this.$refs.form as any).validate();
  }

  private ticketFound() {
    if (this.ticket_found) {
      return true;
    } else {
      return this.$t("validation.ticket_not_found");
    }
  }
}
